.App {

}

.App-logo {
  float: left;
  width: 20%;
  padding: 0 1vw;
}

.App-logo img {
  max-height: 4vh;
  max-width: 20vw;
  pointer-events: none;
}

.App-header {
  background-color: #3B3B3B;
  min-height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  margin-bottom: 2rem;
}

.App-header-title {
  width: 60%;
  text-align: center;
  font-size: calc(.5em + 2vmin);
}

.App-header-title a {
  color: white;
  text-decoration: none;
}

.App-header-title a:hover {
  text-decoration: underline;
}

.App-header-login {
  width: 15%;
  text-align: right;
  margin-right: 5%;
}

.App-link {
  color: #61dafb;
}

.App-section {
  text-align: center;
  padding: 1em;
}

.accordion {
  max-width: 90vw;
  margin: 0 auto;
}

.accordion__button {
  width: auto;
}

.report-ir {
  margin: .5em 1em;
  max-width: 80vw;
}

.report-visible {
  margin: .5em 1em;
  max-width: 80vw;
}

h1 {
  margin-bottom: 1.8rem;
}

h2 {
  margin-bottom: 1.5rem;
}

h3 {
  margin-bottom: 1.2rem;
}

h4 {
  margin-bottom: 1rem;
}

h5, h6 {
  margin-bottom: .8rem;
}